import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'imageAttachment',
    'imageCard',
    'deleteAttachmentButton'
  ];

  attachmentToDelete = []

  connect() { }

  onDeleteButtonPressed() {
    if (this.attachmentToDelete.length > 0) {
      var token = $("meta[name='csrf-token']").attr("content")
      var ids = this.attachmentToDelete.map(el => el.getAttribute('checkbox-image-id'))
      $.ajax({
        url: '/attachments',
        type: 'DELETE',
        dataType: 'json',
        data: { 'attachments_ids': ids },
        headers: {
          'X-CSRF-Token': token
        },
        success: (_) => this.onSuccess.apply(this),
        fail: () => {
          toastr.error('Não foi possível remover o(s) arquivo(s)!')
        }
      }).catch((e) => {
        toastr.error('Não foi possível remover o(s) arquivo(s)!')
      })
    } else {
      toastr.error('Você não selecionou nenhum arquivo!')
    }
  }
  onCheckboxPressed(event) {
    if (event.target.checked) {
      this.showTrashButton(event.target)
    } else {
      this.attachmentToDelete = this.arrayRemove(this.attachmentToDelete, event.target)
      this.hideTrashButton()
    }
  }

  showTrashButton(element){
      this.attachmentToDelete.push(element)
      this.deleteAttachmentButtonTarget.classList.remove('is-hidden')
  }

  hideTrashButton(){
      var children = document.querySelectorAll(".m-3 input")
      var children = Array.from(children)
      if (!children.some((el) =>  el.checked)) {
        this.deleteAttachmentButtonTarget.classList.add('is-hidden')
      }
  }

  onSuccess() {
    this.attachmentToDelete.forEach((value) => this.removeElement.apply(this, [value]))
    this.hideTrashButton()
    toastr.success('Arquivo(s) removido(s) com sucesso!')
  }

  removeElement(value) {
    const attachmentId = value.getAttribute('checkbox-image-id');

    if (value.getAttribute('attachment-type') === 'image') {
      value.parentElement.parentElement.removeChild(value.parentElement);
    } else {
      value.parentElement.parentElement.parentElement.removeChild(value.parentElement.parentElement);
    }
    document.querySelector(`[data-id="${attachmentId}"]`)?.remove();
    this.attachmentToDelete = this.arrayRemove(this.attachmentToDelete, value);
  }
  arrayRemove(array, value) {
    return array.filter(function (el) {
      return el != value;
    })
  }

}
